import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import Customers from "../pages/customers/Customers";
import Products from "../pages/products/Products";
import Login from "../pages/access/Login";
import Profile from "../pages/access/Profile";
import Calender from "../pages/calender/Calender";
import Home from "../pages/home/Home";
import EventBooking from "../pages/event-booking/EventBooking";
import Customer from "../pages/customers/Customer";
import Test from "../pages/test/test";
import Closer from "../pages/closer/Closer";
import AdminAccess from "../pages/access/AdminAccess";
import EventList from "../pages/eventlist/Eventlist";
import CancelEvent from "../pages/access/CancelEvent";
import StopNotifications from "../pages/access/StopNotifications";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/kunden" element={<Customers />} />
      <Route path="/produkte" element={<Products />} />
      <Route path="/login" element={<Login />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settingcockpit" element={<Calender />} />
      <Route path="/event-booking" element={<EventBooking />} />
      <Route path="/customer" element={<Customer />} />
      <Route path="/test" element={<Test />} />
      <Route path="/closercockpit" element={<Closer />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/admintoken" element={<AdminAccess />} />


      <Route path="/absagen" element={<CancelEvent />} />
      <Route path="/deaktivieren" element={<StopNotifications />} />

      <Route path="/booked-events" element={<EventList />} />
    </Routes>
  );
};

export default Routers;
