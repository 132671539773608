import { useCallback, useEffect, useState } from "react";
import APIHandler from "../../handler/APIHandler";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { ToastContainer } from "react-toastify";
import "./eventlist.scss";
import {
  ActivityTimeline,
  Badge,
  Column,
  Table,
  TimelineMarker,
} from "react-rainbow-components";
import { MapComponent } from "../calender/MapComponent.1";

moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const EventList = () => {
  const [allEvents, setAllEvents] = useState([]);


  const [terminArten, setTerminArten] = useState(null);


  const [allUsers, setAllUsers] = useState(null);
  useEffect(() => {

    let user = localStorage.getItem("user");
    if (user == null) {
      window.location.href = "/login";
    }

    async function fetchData() {

      let data = await APIHandler.Calendar.getAllEvents();
      console.log(data);

      let _sortedData = data.sort((a, b) => {
        return new Date(b.erstellt_am) - new Date(a.erstellt_am);
      });

      setAllEvents(_sortedData);

      let terminArten = await APIHandler.Calendar.getAvailableEventTypes();

      setTerminArten(terminArten);

      let users = await APIHandler.User.getAllUsers();
      setAllUsers(users);

    }
    fetchData();
  }, []);
  const filteredCustomers = () => {



    return allEvents;
  }

  return (
    <div className="closer">
      <ToastContainer />
      <h1 className="page-title">Alle gelegten Termine</h1>
      <Row className="mb-5" style={{ padding: "0px 20px 20px 20px" }}>
        <Col lg="12">
          <h4 className="mt-4">Deine Kunden</h4>
          <Table
            emptyTitle={"Keine Daten gefunden"}
            emptyDescription={"Ihnen wurden keine Kunden zugewiesen"}
            isLoading={!allEvents}
            data={filteredCustomers()}
            keyField="id"
          >
            <Column header="Kunde" field="customer" component={({ value }) =>
              <div style={{ cursor: "pointer" }} onClick={
                () => {
                  window.open(`/customer?id=${value.id}`, "_blank");
                }
              }>{value.vorname} {value.nachname}</div>
            } />
            <Column header="Termin-Art" field="terminart_id" component={({ value }) => {
              if (terminArten) {
                return terminArten.find((terminArt) => terminArt.id === value).bezeichnung;
              }
              return value;
            }
            } />

            <Column header="Medium" field="medium" />

            <Column header="Gelegt von" field="setter_id" component={({ value }) => {
              if (allUsers) {
                return allUsers.find((user) => user.id === value)?.kuerzel || "-";
              }
              return "N/A";
            }} />
            <Column header="Gelegt für" field="mitarbeiter_id" component={({ value }) => {
              if (allUsers) {
                return allUsers.find((user) => user.id === value)?.kuerzel || "-";
              }
              return "N/A";
            }} />
            <Column header="Terminzeitpunkt" field="zeitpunkt" component={({ value }) => moment(value).format("DD.MM.YYYY HH:mm")} />

            <Column header="Gelegt am" field="erstellt_am" component={({ value }) => moment(value).format("DD.MM.YYYY HH:mm")} />
          </Table>
        </Col>
      </Row>
    </div>
  );
};

const badgeStyles = { color: '#1de9b6', marginLeft: '0.5rem' };
const IdBadge = ({ value }) => <Badge className="id-badge" label={value} variant="lightest" style={badgeStyles} />;



const StatusBadge = ({ value }) => {

  switch (value) {
    case "NONE":
      return <Badge label={value} variant="lightest" />;
    case "INTERESSENT":
      return <Badge label={value} variant="brand" />;
    case "VALIDE":
      return <Badge label={value} variant="success" />;
    case "ABGESAGT":
      return <Badge label={value} variant="warning" />;
    default:
      return <Badge label={value} variant="lightest" />;
  }

}

export default EventList;
