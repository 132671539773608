import { useEffect, useState } from "react";
import { Button, Input } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import './cancelevent.scss'
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const CancelEvent = () => {
  //get id from url
  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);

  const [canceled, setCanceled] = useState(false);

  useEffect(() => {
    let footer = document.querySelector("footer");
    footer.style.position = "absolute";
    footer.style.width = "100%";
    footer.style.bottom = "0";
  }, []);


  useEffect(() => {

    const urlSearchString = window.location.search;

    const params = new URLSearchParams(urlSearchString);

    setId(params.get("id"));
    setEmail(params.get("email"));

    console.log(
      "Canceling event with id: ",
      id,
      "Canceling event with email: ",
      email
    );

  }, [id, email]);

  if (canceled) {
    return (
      <div className="content-cancel">
        <h1 className="title">Termin abgesagt</h1>
        <p>Dein Termin wurde erfolgreich abgesagt</p>
      </div>
    );
  }


  return (

    <div className="content-cancel">
      <ToastContainer />
      <h1 className="title">Termin absagen</h1>
      <p>Wollen Sie Ihren Termin wirklich absagen?</p>

      <Button
        style={{ marginTop: "30px" }}
        label="Termin absagen"
        variant="destructive"
        className="rainbow-m-around_medium"
        onClick={() => {
          APIHandler.Calendar.cancelEvent(id, email).then((data) => {
            console.log(data);
            if (data.success) {
              setCanceled(true);
              toast.success("Termin erfolgreich abgesagt");
            } else {
              toast.error("Fehler beim Absagen des Termins");
            }
          });
        }}
      />
    </div>
  );
};
export default CancelEvent;
