import { useEffect, useState } from "react";
import { Button, Input } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { useGoogleLogin } from "@react-oauth/google";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCheck,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "reactstrap";

import "./login.scss";

const Login = () => {
  const [user, setUser] = useState(null);



  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [email, password]);

  const inputStyles = {
    width: 300,
  };

  return (
    <Container style={{ padding: "260px 20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div class="wrap-login100">
        <div class="login100-form validate-form" data-bitwarden-watching="1">

          <span class="login100-form-title" style={{ marginBottom: "25px" }}>
            <img src="https://pv-anlagen-dortmund.de/wp-content/uploads/sites/15/2022/12/Winkelnenergielogo.png" />
          </span>
          <span class="login100-form-title">
            Anmeldung
          </span>


          <div class="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
            <input placeholder="E-Mail Adresse" class="input100" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <span class="focus-input100" data-placeholder="Email"></span>
          </div>

          <div class="wrap-input100 validate-input" data-validate="Enter password">
            <span class="btn-show-pass">
              <i class="zmdi zmdi-eye"></i>
            </span>
            <input class="input100" type="password" name="pass" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} />
            <span class="focus-input100" data-placeholder="Password"></span>
          </div>

          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button

                onClick={() => {
                  APIHandler.Auth.login({
                    email: email,
                    password: password
                  }).then((data) => {
                    if (data.success) {
                      setUser(data.user);
                      localStorage.setItem("user", JSON.stringify(data.user));
                      localStorage.setItem("sessionToken", data.sessionToken);


                      window.location.href = "/";


                    } else {
                      setError(data.message);
                    }
                  });

                }}
                class="login100-form-btn">
                Login
              </button>
            </div>
          </div>

          {error &&
            <span className="error-text">{error}</span>
          }
        </div>
      </div>
    </Container>
  );
};
export default Login;
