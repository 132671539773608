import { useCallback, useEffect, useState } from "react";
import APIHandler from "../../handler/APIHandler";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { ToastContainer } from "react-toastify";
import "./closer.scss";
import {
  ActivityTimeline,
  Badge,
  Column,
  Table,
  TimelineMarker,
} from "react-rainbow-components";
import { MapComponent } from "../calender/MapComponent.1";

moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const Closer = () => {
  const [closerData, setCloserData] = useState([]);

  useEffect(() => {
    async function fetchData() {

      let data = await APIHandler.Closer.getCloserData();
      console.log(data);
      setCloserData(data);

    }
    fetchData();
  }, []);
  const filteredCustomers = () => {

    return closerData;
  }

  return (
    <div className="closer">
      <ToastContainer />
      <h1 className="page-title">Closer Cockpit</h1>
      <Row className="mb-5" style={{padding: "0px 20px 20px 20px"}}>
        <Col lg="12">
          <h4 className="mt-4">Deine Kunden</h4>
          <Table
            emptyTitle={"Keine Daten gefunden"}
            emptyDescription={"Ihnen wurden keine Kunden zugewiesen"}
            isLoading={!closerData}
            data={filteredCustomers()}
            keyField="id"
          >
            <Column header="ID" field="id" component={({ value }) => <div onClick={
              () => {
                window.open(`/customer?id=${value}`, "_blank");
              }
            }><IdBadge value={value} /></div>} />
            <Column header="Vorname" field="vorname" />
            <Column header="Name" field="nachname" />
            <Column header="Email" field="email" />
            <Column header="Telefon" field="tel" />
            <Column header="Ort" field="ort" />
            <Column header="PLZ" field="plz" />
            <Column header="Straße" field="straße" />
            <Column header="Hausnummer" field="hausnummer" />
            <Column header="Whrs. Umsatz" field="wahrscheinliche_umsatzgroesse" />
            <Column header="Lead-Quelle" field="lead_quellen_id" />
            <Column header="Status" field="status" component={({ value }) => <StatusBadge value={value} />} />
          </Table>
        </Col>
      </Row>
    </div>
  );
};

const badgeStyles = { color: '#1de9b6', marginLeft: '0.5rem' };
const IdBadge = ({ value }) => <Badge className="id-badge" label={value} variant="lightest" style={badgeStyles} />;



const StatusBadge = ({ value }) => {

  switch (value) {
    case "LEAD":
      return <Badge label={value} variant="lightest" />;
    case "INTERESSENT":
      return <Badge label={value} variant="brand" />;
    case "KUNDE":
      return <Badge label={value} variant="success" />;
    case "ABGELEHNT":
      return <Badge label={value} variant="warning" />;
    default:
      return <Badge label={value} variant="lightest" />;
  }

}

export default Closer;
