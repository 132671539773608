import { useEffect, useState } from "react";
import { Button, Input } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import './cancelevent.scss'
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import logo from "./assets/logo.png";

const StopNotifications = () => {
  //get id from url
  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    let footer = document.querySelector("footer");
    footer.style.position = "absolute";
    footer.style.width = "100%";
    footer.style.bottom = "0";
  }, []);


  useEffect(() => {

    const urlSearchString = window.location.search;

    const params = new URLSearchParams(urlSearchString);

    setId(params.get("id"));
    setEmail(params.get("email"));
    if (id && email) {
      console.log("Stopping notifications for: ", id, email);
      
      APIHandler.Customers.stopNotifications(id, email).then((response) => {
        console.log("Response: ", response);
        toast.success("Sie haben sich erfolgreich abgemeldet");
      }).catch((error) => {
        console.error("Error stopping notifications: ", error);
        toast.error("Fehler beim Abmelden");
      });
    }




  }, [id, email]);




  return (

    <div className="content-cancel">
      <ToastContainer />
      <div class="message-box">
        <div class="logo">
          <img src={logo} alt="Firmenlogo" width="300" />
        </div>
        <h1>Sie haben sich erfolgreich abgemeldet</h1>
        <p>Sie erhalten nun keine Terminbenachrichtigungen zu diesem Ereignis mehr.</p>
      </div>
    </div>
  );
};
export default StopNotifications;
