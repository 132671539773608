import { useEffect, useState } from "react";
import "./header.scss";
import APIHandler from "../handler/APIHandler";

const Header = ({ hasPermission }) => {
  const [profile, setProfile] = useState(null);
  const [activePage, setActivePage] = useState("home");


  const [showMenu, setShowMenu] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("user")));


  }, []);

  console.log("Profile: ", profile);

  useEffect(() => {
    setActivePage(window.location.pathname);
    console.log("Active page: ", window.location.pathname);


    if (activePage.includes("absagen") || activePage.includes("deaktivieren")) {
      setHideHeader(true);
    }

  });

  function logout() {
    APIHandler.Auth.logout();
    setProfile(null);
  }

  if (hideHeader) {
    return (
      <header>
        <h1><img style={{
          marginRight: "10px"
        }} src="https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874" />Winkel Photovoltaik GmbH</h1>

      </header>
    )
  }

  return (



    <header>
      <h1><img style={{
        marginRight: "10px"
      }} src="https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874" />Winkel Photovoltaik GmbH</h1>




      {profile && (
        <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {hasPermission(2) && (
              <li className={activePage.includes("kunden") ? "active" : ""}>
                <a href="/kunden?filter=settingboard">Settingboard</a>
              </li>
            )}
            {hasPermission(3) && (
              <li className={activePage.includes("kunden") ? "active" : ""}>
                <a href="/kunden?filter=closerdokument">Closer Dokument</a>
              </li>
            )}
            {hasPermission(5) && (
              <li className={activePage.includes("kunden") ? "active" : ""}>
                <a href="/kunden">Kunden</a>
              </li>
            )}
            {false && (
              <li className={activePage.includes("settingcockpit") ? "active" : ""}>
                <a href="/settingcockpit">Settingcockpit</a>
              </li>
            )}

            {false && (
              <li className={activePage.includes("closercockpit") ? "active" : ""}>
                <a href="/closercockpit">Closercockpit</a>
              </li>
            )}
            <li className={activePage.includes("event-booking") ? "active" : ""}>
              <a href="/event-booking">Termine buchen</a>
            </li>
            <li className={activePage.includes("booked-events") ? "active" : ""}>
              <a href="/booked-events">Gelegte Termine</a>
            </li>
          </ul>
        </nav>
      )}


      <i class="ri-menu-line menu-icon mobile"
        onClick={() => setShowMenu(!showMenu)}
      ></i>


      <div className={"menu mobile " + (showMenu ? "show" : "")}>
        <i class="ri-menu-unfold-3-line close-icon" onClick={() => setShowMenu(false)}></i>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          {hasPermission(2) && (
            <li className={activePage.includes("kunden") ? "active" : ""}>
              <a href="/kunden?filter=settingboard">Settingboard</a>
            </li>
          )}
          {hasPermission(3) && (
            <li className={activePage.includes("kunden") ? "active" : ""}>
              <a href="/kunden?filter=closerdokument">Closer Dokument</a>
            </li>
          )}
          {hasPermission(5) && (
            <li className={activePage.includes("kunden") ? "active" : ""}>
              <a href="/kunden">Kunden</a>
            </li>
          )}
          {false && (
            <li className={activePage.includes("settingcockpit") ? "active" : ""}>
              <a href="/settingcockpit">Settingcockpit</a>
            </li>
          )}


          {false && (
            <li className={activePage.includes("closercockpit") ? "active" : ""}>
              <a href="/closercockpit">Closercockpit</a>
            </li>
          )}
          <li className={activePage.includes("event-booking") ? "active" : ""}>
            <a href="/event-booking">Termine buchen</a>
          </li>
          <li className={activePage.includes("booked-events") ? "active" : ""}>
            <a href="/booked-events">Gelegte Termine</a>

          </li>
        </ul>
      </div>

      {profile && (
        <div className="profile-area">
          <img
            className="mb-1"
            src={
              "https://api.dicebear.com/8.x/pixel-art/svg?seed=" + profile.email
            }
            alt="Profilbild"
          />
          <div className="profile-info">
            <span>{profile.vorname}</span>
            <button onClick={() => logout()}>Abmelden</button>
          </div>
        </div>
      )}
    </header>
  );
};
export default Header;
