import { useEffect, useState } from "react";
import { Button, Input } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCoffee,
    faCheck,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "reactstrap";

import "./login.scss";
import { useGoogleLogin } from "@react-oauth/google";
import APIHandler from "../../handler/APIHandler";

const AdminAccess = () => {
    const [user, setUser] = useState(null);



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
    }, [email, password]);

    const inputStyles = {
        width: 300,
    };

    const login = useGoogleLogin({
        onSuccess: async ({ code }) => {
            let googleData = await APIHandler.Auth.sendGoogleToken(code);
            console.log(googleData);
        },
        flow: "auth-code",
        scope: "profile email https://www.googleapis.com/auth/calendar",
    });

    return (
        <Container style={{ padding: "260px 20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div class="wrap-login100">
                <div class="login100-form validate-form" data-bitwarden-watching="1">

                    <span class="login100-form-title" style={{ marginBottom: "25px" }}>
                        <img src="https://pv-anlagen-dortmund.de/wp-content/uploads/sites/15/2022/12/Winkelnenergielogo.png" />
                    </span>
                    <span class="login100-form-title">
                        Anmeldung
                    </span>

                    <div class="container-login100-form-btn">
                        <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <Button
                                onClick={login}
                                variant="brand"
                                className="rainbow-m-around_medium d-flex gap-2"
                                borderRadius="semi-rounded"
                            >
                                Mit Google anmelden{" "}
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="rainbow-m-left_medium"
                                />
                            </Button>
                        </div>
                    </div>

                    {error &&
                        <span className="error-text">{error}</span>
                    }
                </div>
            </div>
        </Container>
    );
};
export default AdminAccess;
