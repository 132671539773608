import React from "react";

const CalendarEvent = ({ event }) => {

  switch (event.type) {
    case "event":
      return (
        <div className={"event"}>
          {event.winkelApp && <img className="winkel-app" src={"https://pv-anlagen-dortmund.de/wp-content/uploads/sites/15/2022/12/Winkelnenergielogo.png"} alt="event" />}
          <span className="title">{event.canceled ? "ABGESAGT // " : ""}{event.title}</span>
        </div>
      );
    case "slot":
      return (
        <div className="slot">
          <span className="title">{event.title}</span>
        </div>
      );
    default:
      return <span>saddas</span>;
  }
};

export default CalendarEvent;
