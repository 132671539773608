
import './footer.scss';

const Footer = () => {



  return (
    <footer className="footer">
      <div className='container' >
        <div class="row">

          <div class="mb-4 col-sm-6 col-md-6 col-lg-10">

            <div class="logo">
              <img src="https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874" alt="Winkel Photovoltaik GmbH" />
              <h2 class=" d-flex gap-2 align-items-center mt-2">Winkel Photovoltaik GmbH</h2>
              <p>Lead Management Software</p>
            </div>
          </div>
          <div class="text-center col-lg-12">
            <p class="copyright"> © Copyrights 2024, Winkelapp.de v.0.0.1 </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
